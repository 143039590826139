export const CONSTANTS = {

  privacyAndTerms: {
    privacy: {
      url: {
        en: 'https://www.marsh.com/ca/en/privacy-policy.html',
        fr: 'https://www.marsh.com/fr/fr/privacy-policy.html'
      },
      title: {
        en: 'Privacy notice',
        fr: 'Politique de confidentialité'
      }
    },
    terms: {
      url: {
        en: 'https://www.marsh.com/ca/en/terms-conditions-regulatory-status.html',
        fr: 'https://www.marsh.com/fr/fr/terms-of-use.html'
      },
      title: {
        en: 'Terms of use',
        fr: 'Conditions d’utilisation'
      }
    },
    cookie: {
      url: {
        en: 'https://www.marsh.com/uk/cookie-notice.html',
        fr: 'https://www.marsh.com/fr/fr/cookie-policy.html'
      },
      title: {
        en: 'Cookie notice',
        fr: 'Politique de cookie'
      }
    },
    cookiesScript: 'https://consent.trustarc.com/notice?domain=required.mmc.com&c=teconsent&country=gb&js=nj&noticeType=bb&text=true&gtm=1'
  },

  leftMainMenu: [{
      text: 'Organizational Unit',
      translateKey: 'leftMainMenu.menuOptions.organizationalUnit.title',
      childItems: [{
          translateKey: 'leftMainMenu.menuOptions.organizationalUnit.generalInformation',
          menuLink: '/organizational-unit'
        },
        {
          translateKey: 'leftMainMenu.menuOptions.organizationalUnit.premiseLinking',
          menuLink: '/premise-linking'
        },
        /* {
            translateKey: 'leftMainMenu.menuOptions.organizationalUnit.workarounds',
            menuLink: 'workarounds'
        }, */
        {
          translateKey: 'leftMainMenu.menuOptions.organizationalUnit.library',
          menuLink: 'library'
        }
      ]
    },
    {
      text: 'Human Resources',
      translateKey: 'leftMainMenu.menuOptions.humanResources.title',
      menuLink: '/human-resources',
      childItems: []
    },
    {
      text: 'Other Resources',
      translateKey: 'leftMainMenu.menuOptions.otherResources.title',
      menuLink: '/resource',
      childItems: []
    },
    {
      text: 'BusinessContinuityPlan',
      translateKey: 'leftMainMenu.menuOptions.businessContinuityPlan.title',
      childItems: [{
          translateKey: 'leftMainMenu.menuOptions.businessContinuityPlan.plan',
          menuLink: '/continuity-plan'
        },
        {
          translateKey: 'leftMainMenu.menuOptions.businessContinuityPlan.process',
          menuLink: '/processes'
        },
        {
          translateKey: 'leftMainMenu.menuOptions.businessContinuityPlan.events',
          menuLink: '/events'
        },
        {
          translateKey: 'leftMainMenu.menuOptions.businessContinuityPlan.tests',
          menuLink: '/tests'
        }
      ]
    }
  ],

  administrationMenu: [{
      text: 'Administration',
      textTranslateKey: 'administrationMenu.menuOptions.administration.title',
      childItems: [{
          menuLink: '/profiles',
          textTranslateKey: 'administrationMenu.menuOptions.administration.profiles',
          permissionContext: 'administration.profiles',
          allowedLevels: ['*']
        },
        {
          menuLink: 'users',
          textTranslateKey: 'administrationMenu.menuOptions.administration.users',
          permissionContext: 'administration.users',
          allowedLevels: ['*']
        }
      ]
    },
    {
      text: 'Setting',
      textTranslateKey: 'administrationMenu.menuOptions.setting.title',
      childItems: [{
          menuLink: '/macro-process',
          textTranslateKey: 'administrationMenu.menuOptions.setting.macroProcess',
          permissionContext: 'administration.macroProcess',
          allowedLevels: ['*']
        },
        {
          menuLink: '/process-area',
          textTranslateKey: 'administrationMenu.menuOptions.setting.processArea',
          permissionContext: 'administration.processArea',
          allowedLevels: ['*']
        },
        {
          menuLink: '/process-category',
          textTranslateKey: 'administrationMenu.menuOptions.setting.processCategory',
          permissionContext: 'administration.processCategory',
          allowedLevels: ['*']
        },
        {
          menuLink: '/invalidation-configuration',
          textTranslateKey: 'administrationMenu.menuOptions.setting.invalidationDate',
          permissionContext: 'administration.taskInvalidationDate',
          allowedLevels: ['*']
        }
      ]
    },
    {
      text: 'Document Database',
      textTranslateKey: 'administrationMenu.menuOptions.documentDB.title',
      childItems: [{
        menuLink: '',
        textTranslateKey: 'administrationMenu.menuOptions.documentDB.docNature',
        permissionContext: 'administration.natureDocuments',
        allowedLevels: ['*']
      }]
    }
  ],

  deadlines: [{
      value: 999,
      label: 'dashboard.invalidationDeadline.deadlinesLimit.allDeadlines'
    },
    {
      value: 1,
      label: 'dashboard.invalidationDeadline.deadlinesLimit.oneMonth'
    },
    {
      value: 3,
      label: 'dashboard.invalidationDeadline.deadlinesLimit.threeMonth'
    },
    {
      value: 6,
      label: 'dashboard.invalidationDeadline.deadlinesLimit.sixMonth'
    },
    {
      value: 12,
      label: 'dashboard.invalidationDeadline.deadlinesLimit.oneYear'
    }
  ],

  testStatusColorCodes: {
    All: '#993366',
    Ok: '#66CC66',
    NotOk: '#FF6666',
    NotTestable: '#FFFF66',
    TesterNotPresent: '#FF9966',
    NotTested: '#99CCFF',
    OutOfBound: '#FF99CC',
    PartiallyOk: '#8B0000'
  },

  customAttributeTypes: {
    MACROPROCESS: 'macro-process',
    PROCESSAREA: 'process-area',
    PROCESSCATEGORY: 'process-category'
  },

  modificationHistory: {
    entityTypes: {
      PROCESS: 'continuity-plan-process',
      TASK: 'continuity-plan-task',
    }
  },

  resourceLinking: {
    entityTypes: {
      OU: 'organizational-unit',
      PREMISE: 'premise-resource',
      SERVER: 'server-resource',
      APPLICATION: 'application-resource',
      PROVIDER: 'provider-resource',
      GENERIC: 'generic-resource',
      EQUIPMENT: 'equipment-resource',
      HUMANRESOURCE: 'human-resource',
      PROCESS: 'continuity-plan-process',
      TASK: 'continuity-plan-task',
      EVENT: 'continuity-plan-event',
      TEST: 'continuity-plan-test'
    },

    resourceTypes: {
      OU: 'organizational-unit',
      PREMISE: 'premise-resource',
      SERVER: 'server-resource',
      APPLICATION: 'application-resource',
      PROVIDER: 'provider-resource',
      GENERIC: 'generic-resource',
      EQUIPMENT: 'equipment-resource',
      HUMANRESOURCE: 'human-resource',
      OTHER: 'other-resource',
      DOCUMENT: 'document-resource'
    },

    partTypes: {
      PROCESS: 'continuity-plan-process',
      TASK: 'continuity-plan-task',
      WORKAROUND: 'workaround-solution'
    }

  },

  clientLinking: {
    entityTypes: {
      EVENT: 'continuity-plan-event',
    },
    structureTypes: {
      OU: 'organizational-unit',
    }
  },

  vulnerabilityPeriods: [{
      periodCode: 'None',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.None'
    },
    {
      periodCode: 'Day1',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day1'
    },
    {
      periodCode: 'Day6',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day6'
    },
    {
      periodCode: 'Day11',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day11'
    },
    {
      periodCode: 'Day16',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day16'
    },
    {
      periodCode: 'Day21',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day21'
    },
    {
      periodCode: 'Day26',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Day26'
    },
    {
      periodCode: 'Weekend',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.Weekend'
    },
    {
      periodCode: 'January',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.January'
    },
    {
      periodCode: 'April',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.April'
    },
    {
      periodCode: 'July',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.July'
    },
    {
      periodCode: 'October',
      periodDesc: 'premiseLinking.linkModification.vulnerabilityPeriods.October'
    }
  ],

  claimTypes: [{
      claimTypeCode: 'itUnavailability',
      claimTypeDesc: 'commonText.claimTypes.itUnavailability'
    },
    {
      claimTypeCode: 'oeeaUnavailability',
      claimTypeDesc: 'commonText.claimTypes.oeeaUnavailability'
    },
    {
      claimTypeCode: 'staffUnavailability',
      claimTypeDesc: 'commonText.claimTypes.staffUnavailability'
    },
    {
      claimTypeCode: 'premiseUnavailability',
      claimTypeDesc: 'commonText.claimTypes.premiseUnavailability'
    },
    {
      claimTypeCode: 'cyberattack',
      claimTypeDesc: 'commonText.claimTypes.cyberattack'
    }
  ],

  claimTypesCode: {
    ITUNAVAILABILITY: 'itUnavailability',
    PROVIDERUNAVAILABILITY: 'oeeaUnavailability',
    STAFFUNAVAILABILITY: 'staffUnavailability',
    PREMISEUNAVAILABILITY: 'premiseUnavailability',
    CYBERATTACK: 'cyberattack',
  },

  liftValues: [{
    liftCode: 'downstream',
    liftTypeDesc: 'commonText.liftTypes.downstream'
  },
  {
    liftCode: 'mainstream',
    liftTypeDesc: 'commonText.liftTypes.mainstream'
  },
  {
    liftCode: 'secondary',
    liftTypeDesc: 'commonText.liftTypes.secondary'
  },
  {
    liftCode: 'reading',
    liftTypeDesc: 'commonText.liftTypes.reading'
  },
  // {
  //   liftCode: 'blank',
  //   liftTypeDesc: 'commonText.liftTypes.blank'
  // }
],

  referenceDocs: [{
      translateKey: 'administration.referenceDocs.userGuide',
      file: {
        en: 'User guide SIMCA Marsh BCP_V1.2_EN.pdf',
        fr: 'Guide utilisateur SIMCA Marsh BCP_V1.2.pdf'
      }
    },
    {
      translateKey: 'administration.referenceDocs.useCase1',
      file: {
        en: 'Use_case_1_Creation_process_task_dec_2020_EN.pdf',
        fr: 'Cas_d_usage_1_création_processus_tâche decembre_2020.pdf'
      }
    },
    {
      translateKey: 'administration.referenceDocs.useCase2',
      file: {
        en: 'Use_case_2_Creation_process_dec_2020_EN.pdf',
        fr: 'Cas_d_usage_2_création_processus_decembre_2020.pdf'
      }
    },
    {
      translateKey: 'administration.referenceDocs.useCase3',
      file: {
        en: 'Use_case_3_Creation_resource_IT_App_dec_2020_EN.pdf',
        fr: 'Cas_d_usage_3_Création_ressource_App_informatique_dec_2020.pdf'
      }
    },
    {
      translateKey: 'administration.referenceDocs.useCase4',
      file: {
        en: 'Use_case_4_Creation_workaround_solution_dec_2020_EN.pdf',
        fr: 'Cas_d_usage_4_-Création_d_une_solution_de_contournement_dec_2020.pdf'
      }
    }
  ],

  processTaskResourceTabs: [{
      tabCode: 'RT01',
      tabDesc: 'resources.tabGenericResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT02',
      tabDesc: 'resources.tabSpecificResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT03',
      tabDesc: 'resources.tabPremiseResourceDesc',
      allowImports: false, // true,
      importTemplate: {
        path: 'assets/docs/import-templates/premise-resource/',
        name: 'Import Premise Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT04',
      tabDesc: 'resources.tabServerResourceDesc',
      allowImports: false, // true,
      importTemplate: {
        path: 'assets/docs/import-templates/server-resource/',
        name: 'Import Server Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT05',
      tabDesc: 'resources.tabITApplicationDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/application-resource/',
        name: 'Import Application Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT06',
      tabDesc: 'resources.tabProviderResourceDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/provider-resource/',
        name: 'Import Provider Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT07',
      tabDesc: 'resources.tabDocumentationResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT08',
      tabDesc: 'resources.tabOtherResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT09',
      tabDesc: 'resources.tabHumanResourceDesc',
      allowImports: false
    }
  ],

  resourceTabs: [{
      tabCode: 'RT01',
      tabDesc: 'resources.tabGenericResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT02',
      tabDesc: 'resources.tabSpecificResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT03',
      tabDesc: 'resources.tabPremiseResourceDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/premise-resource/',
        name: 'Import Premise Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT04',
      tabDesc: 'resources.tabServerResourceDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/server-resource/',
        name: 'Import Server Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT05',
      tabDesc: 'resources.tabITApplicationDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/application-resource/',
        name: 'Import Application Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT06',
      tabDesc: 'resources.tabProviderResourceDesc',
      allowImports: true,
      importTemplate: {
        path: 'assets/docs/import-templates/provider-resource/',
        name: 'Import Provider Resource_<language>.xlsx'
      }
    },
    {
      tabCode: 'RT07',
      tabDesc: 'resources.tabDocumentationResourceDesc',
      allowImports: false
    },
    {
      tabCode: 'RT08',
      tabDesc: 'resources.tabOtherResourceDesc',
      allowImports: false
    }
  ],

  processImpactRatings: [{
      code: 1,
      desc: '1',
    },
    {
      code: 2,
      desc: '2',
    },
    {
      code: 3,
      desc: '3',
    },
    {
      code: 4,
      desc: '4',
    }
  ],

  documentTypeList: [{
      docTypeCode: 'Electronic',
      docTypeDesc: 'resources.documentResource.docTypes.Electronic'
    },
    {
      docTypeCode: 'Paper',
      docTypeDesc: 'resources.documentResource.docTypes.Paper'
    },
    {
      docTypeCode: 'Other',
      docTypeDesc: 'resources.documentResource.docTypes.Other'
    }
  ],

  serverTypeList: [{
      serverTypeCode: 'Rack',
      serverTypeDesc: 'resources.serverResource.serverTypes.Rack'
    },
    {
      serverTypeCode: 'Physical',
      serverTypeDesc: 'resources.serverResource.serverTypes.Physical'
    },
    {
      serverTypeCode: 'Virtual',
      serverTypeDesc: 'resources.serverResource.serverTypes.Virtual'
    }
  ],

  premiseTypeList: [{
      premiseTypeCode: 'Site',
      premiseTypeDesc: 'resources.premiseResource.premiseTypes.Site'
    },
    {
      premiseTypeCode: 'Building',
      premiseTypeDesc: 'resources.premiseResource.premiseTypes.Building'
    },
    {
      premiseTypeCode: 'Room',
      premiseTypeDesc: 'resources.premiseResource.premiseTypes.Room'
    }
  ],

  relocationTypeList: [{
      relocationTypeCode: true,
      relocationTypeDesc: 'continuityPlan.planCreation.relocationTypes.processAndTasks'
    },
    {
      relocationTypeCode: false,
      relocationTypeDesc: 'continuityPlan.planCreation.relocationTypes.processOnly'
    }
  ],

  relocationFlagList: [{
      relocationFlagCode: true,
      relocationFlagDesc: 'continuityPlan.planCreation.relocationFlags.rampUpByOU'
    },
    {
      relocationFlagCode: false,
      relocationFlagDesc: 'continuityPlan.planCreation.relocationFlags.rampUpByTask'
    }
  ],

  operationalTimeUnitList: [{
      operationalTimeUnitCode: 'Day',
      operationalTimeUnitDesc: 'resources.premiseResource.operationalTimeUnits.Days'
    },
    {
      operationalTimeUnitCode: 'Hour',
      operationalTimeUnitDesc: 'resources.premiseResource.operationalTimeUnits.Hours'
    },
    {
      operationalTimeUnitCode: 'Week',
      operationalTimeUnitDesc: 'resources.premiseResource.operationalTimeUnits.Weeks'
    },
    {
      operationalTimeUnitCode: 'Month',
      operationalTimeUnitDesc: 'resources.premiseResource.operationalTimeUnits.Months'
    },
    {
      operationalTimeUnitCode: 'Year',
      operationalTimeUnitDesc: 'resources.premiseResource.operationalTimeUnits.Years'
    }
  ],

  premiseTypes: {
    PRIMARY: 'Primary',
    RECOVERY: 'Recovery'
  },

  dependencyTypes: [{
      dependencyTypeCode: 'Internal',
      dependencyTypeDesc: 'resources.providerResource.dependencyTypes.Internal'
    },
    {
      dependencyTypeCode: 'External',
      dependencyTypeDesc: 'resources.providerResource.dependencyTypes.External'
    }
  ],

  linkTypes: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary'
  },

  workaroundActionTypes: {
    IMMEDIATE: 'Immediate',
    AFTER: 'After',
    POST: 'Post'
  },

  accessTypeList: [{
      accessTypeCode: 'Extranet',
      accessTypeDesc: 'resources.applicationResource.accessTypes.Extranet'
    },
    {
      accessTypeCode: 'Internet',
      accessTypeDesc: 'resources.applicationResource.accessTypes.Internet'
    },
    {
      accessTypeCode: 'Intranet',
      accessTypeDesc: 'resources.applicationResource.accessTypes.Intranet'
    },
    {
      accessTypeCode: 'Local Workstation',
      accessTypeDesc: 'resources.applicationResource.accessTypes.LocalWorkstation'
    },
    {
      accessTypeCode: 'Specific manual installation',
      accessTypeDesc: 'resources.applicationResource.accessTypes.SpecificManualInstallation'
    },
    {
      accessTypeCode: 'Specific remote distribution',
      accessTypeDesc: 'resources.applicationResource.accessTypes.SpecificRemoteDistribution'
    },
    {
      accessTypeCode: 'Standard',
      accessTypeDesc: 'resources.applicationResource.accessTypes.Standard'
    },
    {
      accessTypeCode: 'To be defined',
      accessTypeDesc: 'resources.applicationResource.accessTypes.ToBeDefined'
    }
  ],

  categoryList: [{
    categoryCode: 'S2R',
    categoryDesc: 'resources.applicationResource.categoryArr.S2r'
  },
  {
    categoryCode: '-',
    categoryDesc: 'resources.applicationResource.categoryArr.-'
  },
],

criticalityList: [{
  criticalityCode: 'S2R',
  criticalityDesc: 'resources.applicationResource.criticalityArr.S2r'
},
{
  criticalityCode: 'Critical',
  criticalityDesc: 'resources.applicationResource.criticalityArr.Critical'
},
{
  criticalityCode: 'Not critical',
  criticalityDesc: 'resources.applicationResource.criticalityArr.NotCritical'
},
{
  criticalityCode: 'Unaffected',
  criticalityDesc: 'resources.applicationResource.criticalityArr.Unaffected'
},
],

  profileTypeList: [{
      profileTypeCode: 'BCPManager',
      profileTypeDesc: 'commonText.profileTypes.BCPManager'
    },
    {
      profileTypeCode: 'BusinessCorrespondent',
      profileTypeDesc: 'commonText.profileTypes.BusinessCorrespondent'
    },
    {
      profileTypeCode: 'Reader',
      profileTypeDesc: 'commonText.profileTypes.Reader'
    },
    {
      profileTypeCode: 'Administrator',
      profileTypeDesc: 'commonText.profileTypes.Administrator'
    }
  ],
  resultType: [{
      resultTypeCode: 'Ok',
      class: 'ok-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.Ok'
    },
    {
      resultTypeCode: 'NotOk',
      class: 'not-ok-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.NotOk'
    },
    {
      resultTypeCode: 'NotTestable',
      class: 'not-testable-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.NotTestable'
    },
    {
      resultTypeCode: 'TesterNotPresent',
      class: 'tester-not-present-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.TesterNotPresent'
    },
    {
      resultTypeCode: 'NotTested',
      class: 'not-tested-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.NotTested'
    },
    {
      resultTypeCode: 'OutOfBound',
      class: 'out-of-bounds-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.OutOfBound'
    },
    {
      resultTypeCode: 'PartiallyOk',
      class: 'partially-ok-option',
      resultTypeDesc: 'continuityPlan.tests.resultTypes.PartiallyOk'
    }
  ],
  operators: {
    EQUALS: 'eq',
    GREATERTHAN: 'gt',
    GREATERTHANEQUALS: 'gte',
    LESSTHAN: 'lt',
    LESSTHANEQUALS: 'lte'
  },

  roleList: [{
      roleCode: 'Auditor',
      roleDesc: 'humanResources.roleTypes.Auditor'
    },
    {
      roleCode: 'Collaborator',
      roleDesc: 'humanResources.roleTypes.Collaborator'
    },
    {
      roleCode: 'Director',
      roleDesc: 'humanResources.roleTypes.Director'
    }
  ],

  optionsArrayForFilter: [{
      optionCode: 'ALL',
      optionDesc: 'humanResources.optionLabels.labelAll',
    },
    {
      optionCode: 'YES',
      optionDesc: 'humanResources.optionLabels.labelYes',
    },
    {
      optionCode: 'NO',
      optionDesc: 'humanResources.optionLabels.labelNo',
    },
  ],

  resourceTypesList: [{
      resourceCode: 'generic-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.GenericResource'
    },
    {
      resourceCode: 'equipment-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.EquipmentResource'
    },
    {
      resourceCode: 'premise-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.PremiseResource'
    },
    {
      resourceCode: 'server-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.ServerResource'
    },
    {
      resourceCode: 'application-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.ApplicationResource'
    },
    {
      resourceCode: 'provider-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.ProviderResource'
    },
    {
      resourceCode: 'document-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.DocumentResource'
    },
    {
      resourceCode: 'human-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.HumanResource'
    },
    {
      resourceCode: 'other-resource',
      resourceDesc: 'continuityPlan.process.resourceTypes.OtherResource'
    },
  ],

  fileLinking: {
    entityTypes: {
      GENERIC: 'genericResourceId',
      EQUIPMENT: 'equipmentResourceId',
      PREMISE: 'premiseResourceId',
      SERVER: 'serverResourceId',
      APPLICATION: 'applicationResourceId',
      PROVIDER: 'providerResourceId',
      OTHER: 'otherResourceId',
      DOCUMENT: 'documentResourceId',
      HUMANRESOURCE: 'humanResourceId',
      PROCESS: 'continuityPlanProcessId',
      TASK: 'continuityPlanTaskId',
      EVENT: 'continuityPlanEventId',
      TEST: 'continuityPlanTestId'
    },
    actions: {
      FETCH: 'FETCH',
      SAVE: 'SAVE',
    },
  },

  invalidationConfigurationEntities: [{
      entityTypeCode: 'continuity-plan-task',
      entityTypeDesc: 'administration.invalidationConfiguration.taskDescription'
    },
    {
      entityTypeCode: 'continuity-plan-process',
      entityTypeDesc: 'administration.invalidationConfiguration.processDescription'
    },
  ],
};
